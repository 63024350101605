<template>
  <div tab-index-container>
    <slot/>
  </div>
</template>

<script>
import {sleep} from '@shared/utils/commonUtils.mjs';
export default {
  name: 'TabIndexContainer',
  mounted() {
    document.body.addEventListener('mousedown', async () => {
      // await sleep(100);
      // const all = document.querySelectorAll("[tabindex='0']");
      // console.log('all',all);
    });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>