<template>
  <div captcha-switch>
    <label>CAPTCHA SWITCH</label>
    <ToggleButton v-model="model"/>
  </div>
</template>

<script>
import ToggleButton from '@shared/components/common/ToggleButton.vue';

export default {
  name: "CaptchaSwitch",
  components: { ToggleButton },
  props: {
    value: { type: Boolean, default: true }
  },
  watch: {
    model() {
      this.$emit('input', this.model);
    }
  },
  data() {
    return {
      model: true,
    };
  },
  mounted() {
    this.model = this.value;
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[captcha-switch] { .flex(); .justify-center(); .mt(20); .items-center(); .-t(#333333); .pt(20);
  >label { .fs(20); .mr(20); }
}
</style>